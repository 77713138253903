import React from 'react';
import Link from 'gatsby-link';
import Helmet from 'react-helmet';
import Layout from "../layouts";

class BlogPostTemplate extends React.Component {
	render() {
		const post = this.props.data.markdownRemark;

		return (
			<Layout id={"post"}>
				<Helmet title={"Blog posts regarding ip addresses"}/>

				<div className="container">
					<section className="jumbotron">
						<section id="posts">
							<h1>{post.frontmatter.title}</h1>
							<div className="row">
								<div className="col-md-12">
									<Link to="/pricing" className="btn btn-first">
										Infoip API pricing &amp; docs
									</Link>
								</div>
							</div>

							<div dangerouslySetInnerHTML={{__html: post.html}}/>
						</section>
					</section>
				</div>
			</Layout>
		)
	}
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        created_at(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;
