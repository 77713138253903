import React from 'react';
import Link from 'gatsby-link';
import Parallax from "parallax-js";

import logo from "../../theme/img/logo.png";

export default class Header extends React.PureComponent {
	componentDidMount() {
		new Parallax(this._parallaxRef);

	}

	contact = () => {
		window.location = "mailto:romeo.mihalcea@gmail.com";
	};

	render() {
		return <div>
			<div className="map"/>

			<header className="header">
				<div className="container">
					<div className="row">
						<nav className="navbar navbar-default" role="navigation">
							<div className="container-fluid">

								<div className="navbar-header">
									<button type="button" className="navbar-toggle" data-toggle="collapse"
											data-target=".navbar-ex1-collapse">
										<span className="sr-only">Toggle navigation</span>
										<span className="icon-bar"/>
										<span className="icon-bar"/>
										<span className="icon-bar"/>
									</button>
								</div>

								<div id="decorations">
									<ul className="scene" ref={(r) => this._parallaxRef = r}>
										<li id="decor-item-1" className="layer" data-depth="0.10">
											<div className="decor-image-sprite decor-1"/>
										</li>
										<li id="decor-item-2" className="layer" data-depth="0.05">
											<div className="decor-image-sprite decor-2"/>
										</li>
										<li id="decor-item-3" className="layer" data-depth="0.10">
											<div className="decor-image-sprite decor-3"/>
										</li>
										<li id="decor-item-4" className="layer" data-depth="0.10">
											<div className="decor-image-sprite decor-4"/>
										</li>
										<li id="decor-item-5" className="layer" data-depth="0.20">
											<div className="decor-image-sprite decor-5"/>
										</li>
										<li id="decor-item-6" className="layer" data-depth="0.20">
											<div className="decor-image-sprite decor-6"/>
										</li>
										<li id="decor-item-7" className="layer" data-depth="0.30">
											<div className="decor-image-sprite decor-7"/>
										</li>
										<li id="decor-item-8" className="layer" data-depth="0.30">
											<div className="decor-image-sprite decor-8"/>
										</li>
										<li id="decor-item-9" className="layer" data-depth="0.30">
											<div className="decor-image-sprite decor-9"/>
										</li>
										<li id="decor-item-10" className="layer" data-depth="0.20">
											<div className="decor-image-sprite decor-10"/>
										</li>
										<li id="decor-item-11" className="layer" data-depth="0.10">
											<div className="decor-image-sprite decor-11"/>
										</li>
										<li id="decor-item-12" className="layer" data-depth="0.20">
											<div className="decor-image-sprite decor-12"/>
										</li>
										<li id="decor-item-13" className="layer" data-depth="0.10">
											<div className="decor-image-sprite decor-13"/>
										</li>
									</ul>
								</div>

								<div className="collapse navbar-collapse navbar-ex1-collapse">
									<Link
										to="/" className="navbar-brand">
										<img src={logo} alt="Infoip - Your IP address" height="25"/>
									</Link>
									<ul className="nav navbar-nav">
										<li>
											<Link
												to="/">
												Home
											</Link>
										</li>
										<li>
											<Link to="/pricing">API pricing</Link>
										</li>
										<li>
											<Link to="/leak-test">Ip leak test</Link>
										</li>
									</ul>

									<ul className="nav navbar-nav" style={{
										float: 'right'
									}}>
										<li>
											<a href="javascript:void(0)" onClick={this.contact}>Contact</a>
										</li>
									</ul>
								</div>
							</div>
						</nav>
					</div>
				</div>
			</header>
		</div>
	}
}