import React from 'react';
import Helmet from 'react-helmet';

import Header from '../components/Header';
import Footer from '../components/Footer';

import '../theme/styles/bootstrap.css';
import '../../node_modules/prismjs/themes/prism-solarizedlight.css';
import '../theme/styles/change.scss';
import '../theme/styles/main.scss';
import favicon from '../theme/img/favicon.ico';

const TemplateWrapper = ({children}) => (
	<div className="site" style={{
		position     : 'absolute',
		top          : 0,
		bottom       : 0,
		left         : 0,
		right        : 0,
		display      : 'flex',
		minHeight    : '100vh',
		flexDirection: 'column'
	}}>
		<Helmet
			title="Your IP Address"
			meta={[
				{name: 'description', content: 'Sample'},
				{name: 'keywords', content: 'sample, something'},
			]}
		>
			<link rel="shortcut icon" href={favicon} type="image/x-icon"/>
			<link rel="icon" href={favicon} type="image/x-icon"/>
			<link
				href="https://fonts.googleapis.com/css?family=Roboto+Condensed:200|Roboto+Mono|Roboto:300,300i,400,400i"
				rel="stylesheet"/>
		</Helmet>
		<Header/>
		<div className="container" style={{flex: 1}}>
			{children}
		</div>
		<Footer/>
	</div>
);

export default TemplateWrapper;
