import React from 'react';

export default class Footer extends React.PureComponent {
	render() {
		return <footer className="footer">
			<div className="container">
				<div className="row">
					<div className="col-md-7 hidden-sm hidden-xs">
						&nbsp;
					</div>
					<div className="col-md-5 col-sm-12">
						<p className="copyright">DR SOFT S.R.L, Str. Prevederii nr.5, Bucharest, Romania</p>
					</div>
				</div>
			</div>
		</footer>
	}
}